import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FeatureText = styled.p`
    margin: 1rem 0 auto;
	text-align: center;
	font-size: 0.9rem;
	line-height: 1.73;
	letter-spacing: 0.5px;
	color:#FFFFFF;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const FeatureTitle = styled.h2`
    text-align: center;
	font-size: clamp(1.3rem, 13vw, 3.1rem);
	line-height: 1.06;
	letter-spacing: 0.2rem;
	margin: auto;
	opacity: 1;
	color: transparent;
	background-image: linear-gradient(45deg, white, #02F74C); /* Gradient color */
  	-webkit-background-clip: text;
  	background-clip: text;
	
`;

export const FeatureWrapper = styled.div`
    display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-top: 4rem;
	grid-gap: 2rem;
	@media screen and (max-width: 1100px) {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 3rem;
	}
	@media screen and (max-width: 568px) {
		grid-template-columns: repeat(1, 1fr);
	}
	
`;

export const FeatureColumn = styled(motion.div)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #002504;
  /* #bg color of the feature section cards */
  padding: 10px;
  box-shadow: 0 0 10px 2px #02F74C;
  border-radius: 20px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px); /* Apply your desired hover effect */
  }
`;

export const FeatureImageWrapper = styled.div`
    margin-bottom: 1rem;
	border-radius: 50%;
	border: 1px solid #ffffff;
	padding: 30px;
	transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px); /* Apply your desired hover effect */
  }
`;

export const FeatureName = styled.h3`
    font-weight: 200;
	font-size: 1.3rem;
	letter-spacing: 1px;
	color: #C9FFBD;
	@media screen and (max-width: 768px) {
		font-weight: 400;
		font-size: 1rem;
		letter-spacing: 1.3px;
	}
`;

export const FeatureTextWrapper = styled.div`
    position: relative;
	padding: 0 0 20px;
	margin-bottom: 4rem;
`;
