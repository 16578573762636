import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		icon: iconStyle(FaFacebook),
	},
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
	},
	{
		name: 'Twitter',
		icon: iconStyle(FaTwitter),
	},
	{
		name: 'LinkedIn',
		icon: iconStyle(FaLinkedin),
		link: 'https://www.linkedin.com/company/orinova/',
	},
];

export const footerData = [
	{
	  title: 'Main',
	  links: ['Blog', 'FAQs', 'Support', 'About us'],
	},
	{
	  title: 'Product',
	  links: ['Login', 'Personal', 'Business', 'Team'],
	},
	{
	  title: 'Social',
	  links: ['Facebook', 'Instagram', 'YouTube', 'Twitter', 'LinkedIn'],
	  linkUrls: {
		LinkedIn: 'https://www.linkedin.com/company/orinova/',
	  },
	},
  ];
  